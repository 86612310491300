import asset from './asset';
import auth from './auth';
import common from './common';
import market from './market';
import user from './user';
import order from './order';
import trade from './trade';

// ----------------------------------
// SERVER
// ----------------------------------

export const DEV_DOMAIN_NAME = `trust-tr.com`;

const SERVER_DEVELOPMENT = `https://${DEV_DOMAIN_NAME}`;
export const SERVER_PRODUCTION = `https://${window.location.host}`;

export const SERVER = `${__DEV__ ? SERVER_DEVELOPMENT : SERVER_PRODUCTION}`;

export default {
  ...asset(SERVER),
  ...auth(SERVER),
  ...common(SERVER),
  ...market(SERVER),
  ...user(SERVER),
  ...order(SERVER),
  ...trade(SERVER),
};
